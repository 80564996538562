import axios from "axios";
import { PRODUCTS_ENDPOINT } from "../helpers/api";
import { ApiEndpoint } from "./api-endpoint";
import { RestApiService } from "./rest-api.service";

class ProductService {
  restAPIService = new RestApiService();
  getAll() {
    return new Promise((resolve, reject) => {
      const response = axios.get(PRODUCTS_ENDPOINT.getAll);
      resolve(response);
    });
  }

  getUserFrequentProducts({ userId }) {
    return new Promise((resolve, reject) => {
      const response = this.restAPIService.invoke(
        ApiEndpoint.GET_USER_FREQUENT_ORDERED_PRODUCTS,
        { id: userId }
      );
      resolve(response);
    });
  }

  confirmOrder(orderDetails) {
    return new Promise(async (resolve, reject) => {
      let response;
      try {
        response = await axios.post(PRODUCTS_ENDPOINT.order, orderDetails);
        resolve(response.data);
      } catch (error) {
        resolve(error.response.data);
      }
    });
  }

  updateOrder(orderDetails, orderId) {
    return new Promise(async (resolve, reject) => {
      let response;
      try {
        response = await axios.put(`${PRODUCTS_ENDPOINT.order}/${orderId}`, orderDetails);
        resolve(response.data);
      } catch (error) {
        resolve(error.response.data);
      }
    });
  }

  getOrder(orderId) {
    return new Promise(async (resolve, reject) => {
      let response;
      try {
        response = await axios.get(PRODUCTS_ENDPOINT.order + `/${orderId}`);
        resolve(response.data);
      } catch (error) {
        resolve(error.response.data);
      }
    });
  }

  addSpecialInstructions(orderId, specialInstructions) {
    return new Promise(async (resolve, reject) => {
      let response;
      try {
        response = await axios.put(PRODUCTS_ENDPOINT.order + `/${orderId}`, {
          specialInstructions,
        });
        resolve(response.data);
      } catch (error) {
        resolve(error.response.data);
      }
    });
  }

  getPreviousOrders(params) {
    return new Promise(async (resolve, reject) => {
      let response;
      try {
        response = await axios.get(
          PRODUCTS_ENDPOINT.getPreviousOrders, { params }
        );
        resolve(response.data);
      } catch (error) {
        resolve(error.response.data);
      }
    });
  }
}

export default new ProductService();
