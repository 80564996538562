import React from "react";
import { useLoader } from "../hooks";
const loader = require("../../assets/loader.jpeg");

const Loader = () => {
  const { loading } = useLoader((state) => state);
  return (
    <div
      style={{
        position: "fixed",
        height: "100vh",
        width: "100vw",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        justifyContent: "center",
        alignItems: "center",
        display: loading ? "flex" : "none",
      }}
    >
      <img style={{height: '90px'}} src={loader} />
    </div>
  );
};

export default Loader;
