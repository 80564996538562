import axios from "axios";
import { API_URL } from "../app.config";
export class RestApiService {
  invoke(def, apiInput = {}, data, queryParam) {
    axios.defaults.baseURL = API_URL;
    return this.invokeAPI(def.api(apiInput), def.method, data, queryParam);
  }

  invokeAPI(api, method, body, queryMap) {
    const headers = {
      Accept: "*/*",
      "Content-Type": "application/json",
    };
    const httpOptions = { headers: headers, params: queryMap, observe: "body" };
    switch (method) {
      case "POST":
        return this.post(api, body, httpOptions);
      case "GET":
        return this.get(api, httpOptions);
      case "PUT":
        return this.put(api, body, httpOptions);
      case "DELETE":
        return this.delete(api, httpOptions);
      default:
        break;
    }
  }

  post(apiUrl, body, httpOptions) {
    return axios({
      method: "post",
      url: apiUrl,
      headers: httpOptions.headers,
      params: httpOptions.params,
      data: body,
    }).catch((err) => this.handleError(err));
  }

  get(apiUrl, httpOptions) {
    return axios({
      method: "get",
      url: apiUrl,
      params: httpOptions.params,
      headers: httpOptions.headers,
    }).catch((err) => this.handleError(err));
  }

  put(apiUrl, body, httpOptions) {
    return axios({
      method: "put",
      url: apiUrl,
      headers: httpOptions.headers,
      params: httpOptions.params,
      data: body,
    }).catch((err) => this.handleError(err));
  }

  delete(apiUrl, httpOptions) {
    return axios({
      method: "delete",
      url: apiUrl,
      headers: httpOptions.headers,
      params: httpOptions.params,
    }).catch((err) => this.handleError(err));
  }

  handleError(error) {
    return Promise.reject(error);
  }
}
