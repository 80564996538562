import React from 'react';
import './specialInstruction.css';

function SpecialInstructions(props) {

  const [specialInstructions, setSpecialInstructions] = React.useState('');
  
  const submitInstructions = () => {
    const action = {
      type: 'submit',
      value: {
        specialInstructions
      },
      closeModal: true
    };
    props.submitToParent(action);
    setSpecialInstructions('');
  }

  return (
    <section className="special-instructions-wrapper">
      <div className="heading">Please let us know any special requests you might have. We will try our best to consider it.</div>
      <div className="content-container">
        <textarea 
          id="specialInstructions"
          className="netse-textarea"
          value={specialInstructions}
          onChange={ (e) => { setSpecialInstructions(e.target.value) } }
        />
      </div>
      <button id="btn-submitSpecialInstructions" className="btn btn-dark" onClick={() => { submitInstructions() }}>
          Submit
        </button>
    </section>
  )
}

export default SpecialInstructions;