import React, { createContext } from 'react';
import AuthService from '../services/_authentication.service';

export const AuthContext = createContext();

export function useAuth() {
  const [state, setState] = React.useState({
    status: 'pending',
    error: null,
    user: null
  });

  React.useEffect(() => {
    const user = AuthService.getCurrentUser();
    setState({ status: 'success', error: null, user: user ? user : null })
  }, []);


  const isPending = state.status === 'pending';
  const isError = state.status === 'error';
  const isSuccess = state.status === 'success';
  const isAuthenticated = !!state.user && isSuccess;
  const user = state.user || null;

  return {
    ...state,
    isPending,
    isError,
    isSuccess,
    isAuthenticated,
    user
  };
}