import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function VerifyOtpForm(props) {
  const { mobileNumber, isOtpIncorrect, retries } = props;
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [otp, setOtp] = useState();

  function handleMobileNumberChange() {
    props.onHandleChangeMobileNumber();
  }

  function handleOtp(e) {
    const inputValue = e.target.value;
    const isMobileValid = Boolean(inputValue);
    setIsSubmitDisabled(!isMobileValid); 
    setOtp(inputValue);  
  }

  function handleSubmit(e) {
    e.preventDefault();
    setIsSubmitDisabled(true);
    props.onHandleVerifyOtp(mobileNumber, otp);
  }

  function handleResendOTP() {
    props.onResendOtp(mobileNumber);
  }

  return (
    <div className="loginForm-wrapper">
      <div className="heading">We have sent you an OTP. Please Verify</div>

      <form id="verifyOtpform" onSubmit={handleSubmit}>
        <div className="field-group">
          <div className="label">Mobile:</div>
          <div className="displayValue">{mobileNumber}</div>
          {/* <Link to="#" className="right" onClick={handleMobileNumberChange}>Change</Link> */}
        </div>
        <div className="field-group otp">
          <div className="label">OTP:</div>
          <div className="input-field">
            <input
              type="number"
              id="otp"
              name="otp"
              onChange={(e) => handleOtp(e)}
              maxLength={4}
              minLength={4}
              pattern= "\d*"
            />
          </div>

          <button id="btn-sendOtp" className="btn" disabled={isSubmitDisabled}>
            Submit
          </button>
          <Link to="#" className="right" onClick={handleResendOTP}>Resend</Link>
        </div>
        <div className="field-group error">
          <div className="label"></div>
          <div className="error">{isOtpIncorrect ? `Incorrect OTP - Remaining ${retries} attempts` : ''}</div>
        </div>
      </form>
    </div>
  )
}

export default VerifyOtpForm;