import React from "react";
import { ToastContainer } from "react-toastify";
import cookie from 'react-cookies'
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./App.css";
import { Loader } from "./shared/components";

import PrivateRouter from "./components/PrivateRoute";
import AuthenticatedRouteRedirection from "./components/AuthenticatedRouteRedirection";
// CONTEXT
import { AuthContext } from "./context/auth";

// COMPONENTS
import Header from "./components/Header/index";
import Login from "./components/Login/index";
import OrderView from "./components/OrderView";
import OrderPreview from "./components/OrderPreview";
import OrderSummary from "./components/OrderSummary";
import OrderConfirmation from "./components/OrderConfirmation";
import NotFoundUser from "./components/NotFoundUser/NotFoundUser";
import NotFoundPage from "./components/NotFoundPage";
import PreviousOrders from "./components/PreviousOrders";
import OrderDetailsView from "./components/PreviousOrders/details";
import Address from "./components/UserAddress";
import OrderEdit from "./components/OrderEdit";
import { useAuth } from "./context/auth";
import { RestApiService } from "./services/rest-api.service";
import { ApiEndpoint } from "./services/api-endpoint";
import AuthService from './services/_authentication.service';

function App() {
  const { user } = useAuth();
  const restAPIService = new RestApiService();

  React.useEffect(() => {
    if (user && user?._id) {
      getUserDetails(user._id);
      showLogoutAlert();
    }
  }, [user]);

  const getUserDetails = (userId) => {
    restAPIService
      .invoke(ApiEndpoint.GET_USER_DETAILS, { userId })
      .then((res) => {
        let user = JSON.stringify(res.data.data);
        localStorage.setItem("user", user);
        cookie.save('user', user, {path: '/'});
      })
      .catch((error) => {
        console.log("get error", error);
      });
  };


  const showLogoutAlert = () => {
    let userDetails = localStorage.getItem('user') || cookie.load('user');
    userDetails = userDetails!== "undefined" ? JSON.parse(userDetails) : null;
    if (userDetails && !userDetails.zipid) {
      alert('Request you to login again to enjoy our new order placement experience.');
      AuthService.logout();
    } 
  }
  return (
    <AuthContext.Provider value={false}>
      <Router basename={"place-orders"}>
        <div className="wrapper">
          <div className="container">
            <Header />

            <Switch>
              {/* <Route exact path="/login" component={Login} /> */}
              <AuthenticatedRouteRedirection
                exact
                path="/login"
                component={Login}
              />

              <Redirect exact path="/" to="/login" />

              <Route exact path="/address" component={Address} />

              <Route exact path="/not-found-user" component={NotFoundUser} />

              <PrivateRouter exact path="/order" component={OrderView} />
              <PrivateRouter exact path="/order/:orderId" component={OrderView} />

              <PrivateRouter
                exact
                path="/orders/preview"
                component={OrderEdit}
              />

              <PrivateRouter
                exact
                path="/orders/preview/:orderId"
                component={OrderEdit}
              />

              <PrivateRouter
                exact
                path="/orders/:orderId/summary"
                component={OrderSummary}
              />

              <PrivateRouter
                exact
                path="/orders/:orderId/confirmed"
                component={OrderConfirmation}
              />

              <PrivateRouter
                exact
                path="/all-orders"
                component={PreviousOrders}
              />

              <PrivateRouter
                exact
                path="/all-orders/:orderId"
                component={OrderDetailsView}
              />

              <Route path="*" component={NotFoundPage} />
            </Switch>
            <Loader />
          </div>
        </div>
      </Router>
      <ToastContainer />
    </AuthContext.Provider>
  );
}

export default App;
