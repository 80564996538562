export class ApiEndpoint {
  static VALIDATE_PINCODE = {
    method: "GET",
    api: (input) => `zipcode/${input.code}`,
  };

  static VARIFY_OTP = {
    method: "GET",
    api: (input) => `verify/${input.otp}`,
  };

  static CREATE_PINCODE = {
    method: "POST",
    api: (input) => `zipcode/${input.code}`,
  };

  static CREATE_USER = {
    method: "POST",
    api: () => `customers`,
  };

  static CREATE_INTERESTED_USER = {
    method: "POST",
    api: () => `interested`,
  };

  static UPDATE_USER = {
    method: "PUT",
    api: (input) => `customers/update/${input.userId}`,
  };

  static GET_AREA_LIST = {
    method: "GET",
    api: () => `areas/list`,
  };

  static GET_AREA_BY_ZIP_ID = {
    method: "GET",
    api: (input) => `areas/zipcode/${input.id}`,
  };

  static GET_AREA_BY_ZIPId = {
    method: "GET",
    api: (input) => `zipcode/zipId/${input.id}`,
  };

  static CREATE_AREA_FOR_PICODE = {
    method: "POST",
    api: (input) => `areas/zipcode/${input.id}`,
  };

  static GET_USER_DETAILS = {
    method: "GET",
    api: (input) => `customers/userId/${input.userId}`,
  };

  static GET_ALL_CATEGORIES = {
    method: "GET",
    api: () => `categories/all`,
  };

  static GET_FREQUENT_ENABLED = {
    method: "GET",
    api: (input) => `frequency/isEnabled/user/${input.id}`,
  };

  static GET_USER_FREQUENT_ORDERED_PRODUCTS = {
    method: "GET",
    api: (input) => `/frequency/user/${input.id}`,
  };
}
