import React from 'react';
import './modalComponent.css';
import CloseImage from '../../../assets/icons/ic_clear.png';

function ModalComponent({ component: Component, ...rest }) {

  const { showModal, onClose } = rest;

  const handleModal = (props) => {
    rest.submitToParent(props);
  }

  return (
    <section className={`popup-wrapper ${showModal ? '' : 'displayNone'} `}>
      <div className="popup-container">
        <Component submitToParent={handleModal}/>
        <div className="btn-close-popup" onClick={onClose}>
          <img src={CloseImage} alt="close" />
        </div>
      </div>
    </section>
  )
}

export default ModalComponent;