import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import DeliveryComponent from "../DeliveryComponent/index";
import OrderListComponent from "./list";
import { useAuth } from "../../context/auth";
import ProductService from "../../services/_product.service";
import "./previousOrders.css";
import Pagination from "../Pagination";

function PreviousOrders() {
  const { user } = useAuth();
  const PAGE_SIZE = 15;
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(1);
  const [orders, setOrders] = useState([]);
  const [isOrdersFetched, setIsOrdersFetched] = useState("starting");

  React.useEffect(() => {
    if (user) {
      getOrders();
    }
  }, [user]);

  const getOrders = async (page = 0) => {
    const userDetails = user ? JSON.parse(user) : null;
    if (!userDetails || !userDetails._id) return;
    setIsOrdersFetched("pending");
    const params = {
      userId: userDetails._id,
      pageSize: PAGE_SIZE,
      pageNumber: page ? page - 1 : page,
    };
    const response = await ProductService.getPreviousOrders(params);
    if (!response.success) return;
    const orders = response.data.allDocuments;
    const totalCount = response.data.meta.total;
    setTotalCount(totalCount);
    setCurrentPage(page ? page : page + 1);
    setOrders(orders);
    setIsOrdersFetched("done");
  };

  return (
    <Fragment>
      <DeliveryComponent />

      <OrderListComponent orders={orders} />
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={totalCount}
        siblingCount={3}
        pageSize={PAGE_SIZE}
        onPageChange={(page) => getOrders(page)}
      />
      <Link to="/" className="btn btn-dark minwidth-200">
        Place new order
      </Link>
    </Fragment>
  );
}

export default PreviousOrders;
