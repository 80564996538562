import axios from "axios";
import cookie from "react-cookies";
import { AUTH_ENDPOINT } from "../helpers/api";

class AuthService {
  fakeAuth = false;
  fakeUser = {
    username: "Sankar",
    address: "Narayan",
  };

  login(username, password) {
    return axios
      .post(AUTH_ENDPOINT, {
        username,
        password,
      })
      .then((res) => {
        if (res.data.accessToken) {
          // localStorage.setItem("user", JSON.stringify(res.data));
        }
        return res.data;
      });
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    cookie.remove("user", { path: "/" });
    cookie.remove("isUserAdmin", { path: "/" });
    window.location.href = window.location.origin;
  }

  register() {
    // Not Yet supported
  }

  getCurrentUser() {
    const value = localStorage.getItem("user") || cookie.load("user") || null;
    return value !== "undefined" ? value : null;
  }
}

export default new AuthService();
