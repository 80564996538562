import React, { useState } from "react";

function SendOtpForm(props) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [mobileNumber, setMobileNumber] = useState();

  
  function handleMobileNumber(e) {
    const inputValue = e.target.value.slice(0,10);
    const regex = /[0-9]{10}/;
    const isMobileValid = regex.test(inputValue);
    setIsSubmitDisabled(!isMobileValid); 
    setMobileNumber(inputValue);
  }

  function handleNumericDigits(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
        return false;
    }
    return true;
  }

  function handleSubmit(e) {
    e.preventDefault();
    setIsSubmitDisabled(true)
    props.onHandleSendOtpFormSubmit(mobileNumber);
  }


  return (
    <div className="loginForm-wrapper">
      <div className="heading">
        Please verify your phone number to start placing your order. Just have
        to do this once!
      </div>

      <form id="sendotpform" onSubmit={handleSubmit}>
        <div className="field-group">
          <div className="label">Mobile:</div>
          <div className="input-field">
            <input
              type="number"
              id="mobileInput"
              name="mobileInput"
              onChange={(e) => handleMobileNumber(e)}
              onKeyPress={(e) => handleNumericDigits(e)}
              value={mobileNumber}
              pattern= "\d*"
              tabIndex={0}
              onSubmit={handleSubmit}
            />
          </div>

          <button id="btn-sendOtp" className="btn" disabled={isSubmitDisabled}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default SendOtpForm;
