import React, { Fragment, useState } from "react";
import cookie from "react-cookies";
import DeliveryComponent from "../DeliveryComponent/index";
import AdminUserComponent from "../AdminUserComponent/index";
import ProductCatalog from "../ProductCatalog";
import AuthService from "../../services/_authentication.service";

function OrderView() {
  const [customerData, setConsumerData] = useState("");

  const handleCustomerData = (data) => {
    setConsumerData(data);
  };

  const isUserAdmin = localStorage.getItem("isUserAdmin") ||
  (cookie.load("isUserAdmin") !== "undefined"
    ? cookie.load("isUserAdmin")
    : null);

  React.useEffect(() => {
    const customerDetails = JSON.parse(AuthService.getCurrentUser())
    setConsumerData({...customerDetails, id: customerDetails._id});
  }, []);

  return (
    <Fragment>
      {isUserAdmin === 'true'? (
        <AdminUserComponent onHandleCustomerData={handleCustomerData} />
      ) : (
        <DeliveryComponent />
      )}
      <ProductCatalog customerData={customerData} isUserAdmin={isUserAdmin} />
    </Fragment>
  );
}

export default OrderView;
