import React from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { useSelectedOrder } from '../../shared/hooks';

function OrderItem({ order }) {

  const { setSelectedOrder } = useSelectedOrder((state) => state);
  const history = useHistory();
  const editDisabled = order => {
    const orderDate = moment(order.orderDate);
    return orderDate.isSame(new Date(), "day")
  }

  const editOrder = order => {
    setSelectedOrder(order.items);
    history.push(`orders/preview/${order._id}`);
  }

  return (
    <div className="order-item">
      <div className="left">
        <div className="subtitle">{order?.items?.length ? order?.items?.length > 1 ? `${order?.items?.length} Items` : `${order?.items?.length} Item` : null}
        </div>
        <div className="title">{moment(order.orderDate).format('MMM DD, YYYY')}</div>
        <div className="caption">{order.status === 'dispatched' ? 'Delivered' : 'Order Placed'}</div>
      </div>
      <div className="right">
        {
          editDisabled(order) ?
            <button onClick={() => editOrder(order)} className="btn-edit">
              Edit
            </button>
            :
            null
        }
        <Link to={`/all-orders/${order._id}`} className="btn-view">
          View
        </Link>
      </div>
    </div >
  )
}

function OrderList(props) {
  const orders = props.orders || [];

  return (
    <section className="orders-wrapper">
      <div className='order-menu'>
        <h4>My Orders</h4>
      </div>
      <div className="orders-list-container">
        {orders.map((order, index) => <OrderItem key={index} order={order} />)}
      </div>
    </section>
  )
}

export default OrderList;
