import create from "zustand";

const useMappedProducts = create((set) => ({
    mappedProducts: null,
    setMappedProduct: mappedProducts => set(() => {
        sessionStorage.setItem('mappedProducts', JSON.stringify(mappedProducts));
        return mappedProducts
    }),
    removeMappedProduct: () => set(() => {
      sessionStorage.removeItem('mappedProducts');
      return null;
    })
}));

export default useMappedProducts;
