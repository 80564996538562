import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CloseImage from "../../assets/icons/ic_clear.png";
import { Modal } from "react-responsive-modal";
import styled from "styled-components";

function ProductItem(props) {
  const { product, isOrderSummary } = props;
  const productId = product._id;
  const quantityInputId = `${productId}-quantity`;
  const unitInputId = `${productId}-unit`;
  let defaultUom = product.associatedUOMs && product.associatedUOMs[0];

  if (product.frontendUomId) {
    product.associatedUOMs.forEach((associatedUom) => {
      if (associatedUom.uomId.toString() === product.frontendUomId.toString()) {
        defaultUom = associatedUom;
      }
    });
  }

  const [isFocused, setFocused] = useState(false);
  const [quantity, setQuantity] = useState(
    props.product?.formDetails?.quantity || ""
  );
  const [selectedUom, setSelectedUom] = useState({});
  const [quantityError, setQuantityError] = useState(false);
  const [isMaxError, setIsMaxError] = useState(false);
  const [removeProductWarming, setRemoveProductWarming] = useState(false);

  const formQuantity = product?.formDetails?.quantity;

  // Ref
  const ref = React.createRef();

  useEffect(() => {
    if (selectedUom?.conversionNumber) {
      onQuantityChange();
    }
  }, [quantity, selectedUom]);

  useEffect(() => {
    if (props.hasError) {
      props.hasError(quantityError);
    }
  }, [quantityError]);

  useEffect(() => {
    setDefaultUom();
  }, []);

  const setDefaultUom = () => {
    let defaultUom = null;
    if (
      product.associatedUOMs &&
      product.associatedUOMs.length &&
      product.frontendUomDetails
    ) {
      if (
        product.associatedUOMs.find(
          (elem) => elem.uomId === product.frontendUomDetails._id
        )
      ) {
        defaultUom = product.associatedUOMs.find(
          (elem) => elem.uomId === product.frontendUomDetails._id
        );
      } else {
        defaultUom = product.associatedUOMs && product.associatedUOMs[0];
      }
    } else if (product.frontendUomDetails) {
      defaultUom = {
        ...product.frontendUomDetails,
        uomId: product.frontendUomDetails._id,
      };
    } else if (product.associatedUOMs && product.associatedUOMs.length) {
      defaultUom = product.associatedUOMs && product.associatedUOMs[0];
    }

    if (product?.formDetails?.uomId) {
      product.associatedUOMs.forEach((associatedUom) => {
        if (
          associatedUom.uomId.toString() ===
          product.formDetails.uomId.toString()
        ) {
          defaultUom = associatedUom;
        }
      });
    }
    setSelectedUom(defaultUom);
  };

  function calculateProductPrice(quantity, selectedUom, price) {
    return quantity * selectedUom.conversionNumber * price;
  }
  const onQuantityChange = () => {
    product.formDetails = {
      productId,
      quantity,
      uomId: selectedUom.uomId,
      isError: quantityError,
      price: calculateProductPrice(quantity, selectedUom, product.price),
    };
    if (props.handleChange) {
      props.handleChange(product, props.productIndex, quantityError);
    }
  };
  const handleQuantity = (e) => {
    const currentQuantity = e.target.value;
    if (currentQuantity && !e.target.validity.valid) {
      e.preventDefault();
      return;
    }
    if (!currentQuantity.length) {
      setQuantityError(false);
      setQuantity("");
      return;
    }

    // Validate the text box with number
    let regex = /^[1-9]\d*$/; // allow only numbers [0-9]
    if (
      selectedUom &&
      selectedUom.details &&
      selectedUom.details.name === "Kg"
    ) {
      regex = /(\d+(\.\d{0,4})?)/;
    }
    if (!regex.test(currentQuantity)) return;

    if (
      selectedUom.minQuantity > currentQuantity ||
      currentQuantity > selectedUom.maxQuantity
    ) {
      setQuantityError(true);
      setIsMaxError(selectedUom.minQuantity > currentQuantity);
      // return ;
    } else {
      setQuantityError(false);
    }
    // Validate whether
    setQuantity(currentQuantity);
  };

  const handleWeight = (selectedUomId) => {
    const selectedUom = product.associatedUOMs.find(
      (uom) => uom.uomId === selectedUomId
    );
    setQuantity("");
    setSelectedUom(selectedUom);
  };

  const setSelectedWeight = (selectedUomId) => {
    const selectedUom = product.associatedUOMs.find(
      (uom) => uom.uomId === selectedUomId
    );
    setSelectedUom(selectedUom);
  };

  const handleFocus = (e) => {
    setFocused(!isFocused);
  };

  const clearQuantity = () => {
    setQuantityError(false);
    setQuantity(props.fromEditOrder ? "1" : "");
    product.formDetails = {
      productId,
      quantity: props.fromEditOrder ? "1" : "",
      uomId: selectedUom.uomId,
      isError: quantityError,
      price: calculateProductPrice(0, selectedUom, product.price),
    };
    props.handleChange(product, props.productIndex, quantityError);
  };

  const removeProduct = () => {
    setRemoveProductWarming(false);
    props.onHandleRemoveNode(product);
  };

  const openRemoveProduct = (flag) => {
    setRemoveProductWarming(flag);
  };

  const handleEditMode = () => {
    setDefaultUom();
    props.onHandleEditMode(ref);
  };

  const openEditModel = () => {};

  const onQtyIncrease = () => {
    let qty = quantity;
    qty++;
    setQuantity(qty);
  };

  const onQtyChange = (value) => {
    if (value < 0 || (value + "").includes("-")) {
      setQuantity(0);
    } else {
      setQuantity(value);
    }
  };

  const onQtyDecrease = () => {
    let qty = quantity;
    if (quantity > 0) {
      qty--;
      setQuantity(qty);
    } else {
      setQuantity(0);
    }
    onQuantityChange();
  };

  const getItemBadge = (selectedProduct) => {
    if (selectedProduct.tags) {
      if (selectedProduct.tags.toLowerCase() === "new") {
        return <span className="red-badge">New Item</span>;
      }
      if (selectedProduct.tags.toLowerCase() === "lowestprice") {
        return <span className="green-badge">Lowest Price</span>;
      }
      return null;
    }
    return null;
  };

  return (
    <div
      ref={ref}
      className={
        isOrderSummary
          ? formQuantity
            ? "products-item"
            : "products-item displayNone"
          : "products-item"
      }
      id={`product-${productId}`}
    >
      {product?.category?.viewType &&
      product?.category?.viewType === "Embedded" ? (
        <div className="card-container">
          <div className="product-img">
            <img
              src={
                product?.media?.length &&
                product.media.find((elem) => elem.primary)
                  ? product.media.find((elem) => elem.primary).url
                  : `${process.env.PUBLIC_URL}/pickles.jpg`
              }
              alt=""
            />
          </div>
          <div className="product-info">
            <div>
              {getItemBadge(product)}
              <div className="product-name">{product.name}</div>
              <div className="product-priceInfo">
                <span className="product-currency">&#8377; </span>
                <span className="product-price">{product.price}</span>
                <span className="product-priceUnit">
                  /{product?.defaultUomDetails?.name}
                </span>
              </div>
            </div>
            <div className="product-quantity">
              <span>Quantity</span>
              <span className="qty-handling">
                <button
                  className="value-btn decrease"
                  onClick={onQtyDecrease}
                  type="button"
                >
                  -
                </button>
                <input
                  type="number"
                  value={quantity}
                  onChange={(e) => onQtyChange(e.target.value)}
                />
                <button
                  className="value-btn increase"
                  onClick={onQtyIncrease}
                  type="button"
                >
                  +
                </button>
              </span>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            className={isOrderSummary ? "edit-first-column" : "first-column"}
          >
            {getItemBadge(product)}
            <div className="product-name">{product.name}</div>
            <div className="product-priceInfo">
              <span className="product-currency">&#8377; </span>
              <span className="product-price">{product.price}</span>
              <span className="product-priceUnit">
                /{product?.defaultUomDetails?.name}
              </span>
            </div>
          </div>

          <div
            className={isOrderSummary ? "edit-second-column" : "second-column"}
          >
            <div
              className="product-quantity-input"
              onFocus={handleFocus}
              onBlur={handleFocus}
            >
              <div className="customProductInput">
                <input
                  type="text"
                  id={quantityInputId}
                  name={quantityInputId}
                  onChange={handleQuantity}
                  value={quantity}
                  pattern="^[0-9]*(\.[0-9]{0,2})?$"
                  disabled={isOrderSummary}
                />
                <span
                  className={isOrderSummary ? "btn-input-unit" : "displayNone"}
                >
                  {selectedUom?.details?.name}
                </span>
                <span
                  onClick={() => {
                    clearQuantity();
                  }}
                  className={
                    !isOrderSummary
                      ? "btn-input-clear"
                      : "btn-input-clear displayNone"
                  }
                >
                  {!props.hideCross && <img src={CloseImage} alt="X" />}
                </span>
              </div>
              <span
                onClick={() => {
                  openRemoveProduct(true);
                }}
                className={isOrderSummary ? "btn-input-delete" : "displayNone"}
              >
                <img src={CloseImage} alt="X" />
              </span>
            </div>
            {quantityError ? (
              <div className="quantity-error">
                {isMaxError
                  ? `Minimum ${selectedUom.minQuantity} ${selectedUom.details.name}`
                  : `Maximum ${selectedUom.maxQuantity} ${selectedUom.details.name}`}
              </div>
            ) : null}
          </div>

          <div
            className={isOrderSummary ? "edit-third-column" : "third-column"}
          >
            {!isOrderSummary ? (
              <div className="product-unit-select">
                <div className="customProductSelect">
                  <select
                    id={unitInputId}
                    name={unitInputId}
                    onChange={(event) => handleWeight(event.target.value)}
                    value={selectedUom.uomId}
                  >
                    {selectedUom.uomId}
                    {product.associatedUOMs &&
                      product.associatedUOMs.map((associatedUom) => (
                        <option
                          value={associatedUom.uomId}
                          id={unitInputId + "-" + associatedUom.uomId}
                          key={unitInputId + "-" + associatedUom.uomId}
                          // selected={associatedUom.uomId === selectedUom.uomId}
                        >
                          {associatedUom.details.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            ) : props.onHandleEditMode ? (
              <div className="product-edit-container">
                <button
                  type="button"
                  onClick={() => {
                    props.onHandleEditMode(product);
                  }}
                >
                  Change
                </button>
              </div>
            ) : null}
          </div>
        </>
      )}
      <Modal
        styles={{ width: "80%" }}
        open={removeProductWarming}
        onClose={() => setRemoveProductWarming(false)}
      >
        <p style={{ marginTop: "0", paddingRight: "20px" }}>
          Are you sure you want to remove this item?
        </p>
        <YesButton onClick={() => removeProduct()}>Yes, Remove</YesButton>
        <CancelButton onClick={() => setRemoveProductWarming(false)}>
          Cancel
        </CancelButton>
      </Modal>
    </div>
  );
}

export default ProductItem;

const YesButton = styled.button`
  color: red;
  padding: 10px 20px;
  border: 2px solid red;
  border-radius: 4px;
  background: #fff;
  font-size: 14px;
`;

const CancelButton = styled.button`
  padding: 10px 20px;
  border: none;
  background: #fff;
  font-size: 14px;
`;
