// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/background-rect.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "* {\n  box-sizing: border-box;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\nbody, html {\n  width: 100%;\n  height: 100%;\n  font-family: 'Roboto', sans-serif;\n}\n\n#root {\n  height: 100%;\n}\n\n.wrapper {\n  width: 100%;\n  height: calc(100% - 62px);\n}\n\n.container {\n  width: 100%;\n  height: 100%;\n}\n\n.btn {\n  font-size: 16px;\n  font-weight: 700;\n  line-height: 19px;\n  border-radius: 5px;\n  background: #61AC47;\n  padding: 10px 25px;\n  border: none;\n  color: #fff;\n  cursor: pointer;\n  outline: none;\n}\n\n.btn:active, .btn:focus {\n  outline: none;\n  -webkit-user-select: none;\n          user-select: none;\n}\n\n.btn-inverted {     \n  background: #fff;\n  color: #1f5d23;\n}\n\n.btn-inverted-bordered {\n  border: 1px solid #1f5d23;\n  border-radius: 5px;\n}\n\n.btn-dark {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  text-decoration: none;\n}\n\nbutton:disabled {\n  cursor: not-allowed;\n  opacity: .5;\n}\n\n.error {\n  font-size: 12px;\n  line-height: 14px;\n  color: #D0021B;\n}\n\n.displayNone {\n  display: none !important;\n}\n\n.visibilityHidden {\n  visibility: hidden;\n  /* z-index: -1; */\n}\n\n.minwidth-200 {\n  display: block;\n  width: 250px;\n  margin: 0px auto;\n  text-align: center;\n  position: relative;\n}", ""]);
// Exports
module.exports = exports;
