import moment from "moment";
import React, { useEffect, useState } from "react";
import ProductService from "../../services/_product.service";
import {
  useLoader,
  useSelectedOrder,
  useMappedProducts,
} from "../../shared/hooks";
import { numberFormat } from "../../helpers/utils";
import ProductItem from "../ProductCatalog/ProductItem";
import DeliveryDateService from "../../services/_deliveryDate.service";
import DeliveryComponent from "../DeliveryComponent";
import { ApiEndpoint } from "../../services/api-endpoint";
import { RestApiService } from "../../services/rest-api.service";
import { useHistory } from "react-router-dom";
import { Modal } from "react-responsive-modal";
import styled from "styled-components";
import "./OrderEdit.css";
const user = JSON.parse(localStorage.getItem("user")) || null;

function showPriceWarning() {
  const timeFormat = "HH:mm:ss";
  const currentTime = moment().utcOffset("+05:30");

  return currentTime.isBetween(
    moment("00:00:00", timeFormat).utcOffset("+05:30"),
    moment("12:00:00", timeFormat).utcOffset("05:30")
  );
}

const OrderEdit = (props) => {
  const restAPIService = new RestApiService();
  const orderId = props.match.params.orderId;
  const [minOrderModalShow, setMinOrderModalShow] = React.useState(false);
  const [minOrderedPrice, setMinOrderedPrice] = React.useState(0);
  const { setLoader } = useLoader((state) => state);
  const [deliveryDate, setDeliveryDate] = useState();
  const [totalOrderedPrice, setTotalOrderedPrice] = useState(0);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [isConfirmClicked, setIsConfirmClicked] = useState(false);
  const [isConfirmOrderValid, setIsConfirmOrderValid] = useState(true);
  const [isDeliveryDateSet, SetIsDeliveryDateSet] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const isPriceWarning = showPriceWarning();
  let { selectedOrder, setSelectedOrder, removeSelectedOrder } =
    useSelectedOrder((state) => state);
  if (!selectedOrder && sessionStorage.getItem("selectedOrder")) {
    selectedOrder = JSON.parse(sessionStorage.getItem("selectedOrder"));
  }
  const { setMappedProduct, removeMappedProduct } = useMappedProducts(
    (state) => state
  );
  const queryParams = new URLSearchParams(props.location.search);
  const isUserAdmin = queryParams.get("isUserAdmin");
  const customerData = JSON.parse(queryParams.get("customerData"));
  const history = useHistory();

  useEffect(() => {
    setProductsList();
  }, []);

  const setProductsList = () => {
    let products = [];
    if (selectedOrder) {
      let totalPrice = 0;
      products = selectedOrder.map((product, index) => {
        let selectedProduct = null;
        if (product.productDetails) {
          const selectedUom = product.productDetails.associatedUOMs.find(
            (uom) => product.uomId.toString() === uom.uomId.toString()
          );
          selectedProduct = {
            ...product.productDetails,
            frontendUomDetails: {
              name: product.uomDetails.name,
              _id: product.productDetails.frontendUomId,
            },
            formDetails: {
              productId: product.productDetails._id,
              quantity: product.quantity,
              uomId: selectedUom.uomId,
              isError: false,
              price: calculateProductPrice(
                product.quantity,
                selectedUom,
                product.productDetails.price
              ),
            },
            actualIndex: index,
          };
        } else {
          selectedProduct = {
            ...product,
            actualIndex: index,
          };
        }
        if (
          selectedProduct.formDetails &&
          !selectedProduct.formDetails.isError &&
          selectedProduct.formDetails.price
        ) {
          totalPrice += selectedProduct.formDetails.price;
        }
        return selectedProduct;
      });
      setTotalOrderedPrice(totalPrice);
      setProducts(products);
    }
  };

  const handleDeliveryDate = async () => {
    const response = await DeliveryDateService.getDeliveryDate();
    if (response.success) {
      const deliveryDate = response.data.deliveryDate;
      setDeliveryDate(deliveryDate);
    }
  };

  if (!isDeliveryDateSet) {
    handleDeliveryDate();
    SetIsDeliveryDateSet(true);
  }

  function calculateProductPrice(quantity, selectedUom, price) {
    return quantity * selectedUom.conversionNumber * price;
  }

  function orderedProducts() {
    if (!products) return [];

    return products.filter((product) => {
      if (
        product.formDetails &&
        !product.formDetails.isError &&
        product.formDetails.price
      ) {
        return product.formDetails;
      }
    });
  }

  const addMoreItems = () => {
    if (orderId) {
      history.push(`/order/${orderId}`);
    } else {
      history.push(`/order`);
    }
  };

  const handleEditMode = (product) => {
    setSelectedProduct({ ...product });
    setEditModel(true);
  };

  const handleChange = (product, productIndex) => {
    products[productIndex] = { ...product };
    let totalPrice = 0;
    products.filter((product) => {
      const selectedUom = product.associatedUOMs.find(
        (uom) => product.formDetails.uomId.toString() === uom.uomId.toString()
      );
      if (
        product.formDetails &&
        !product.formDetails.isError &&
        product.formDetails.price
      ) {
        product.frontendUomId = selectedUom.uomId;
        product.frontendUomDetails = selectedUom.details;
        totalPrice += product.formDetails.price;
        return product.formDetails;
      }
    });
    setTotalOrderedPrice(totalPrice);
    setEditModel(false);
    setProducts([]);
    products.forEach((product, index) => (product.actualIndex = index));
    setSelectedOrder(products);
    setTimeout(() => {
      setProducts(products);
    }, 5);
  };

  const handleRemoveNode = (selectedProduct) => {
    let totalPrice = 0;
    const index = products.findIndex(
      (product) => product._id === selectedProduct._id
    );
    products.splice(index, 1);
    products.forEach((product, index) => {
      product.actualIndex = index;
      const selectedUom = product.associatedUOMs.find(
        (uom) => product.formDetails.uomId.toString() === uom.uomId.toString()
      );
      if (
        product.formDetails &&
        !product.formDetails.isError &&
        product.formDetails.price
      ) {
        product.frontendUomId = selectedUom.uomId;
        product.frontendUomDetails = selectedUom.details;
        totalPrice += product.formDetails.price;
      }
    });
    setTotalOrderedPrice(totalPrice);
    setProducts(products);
    setSelectedOrder(products);
  };

  const handleConfirmOrder = async () => {
    setIsConfirmOrderValid(false);
    if (!isConfirmOrderValid) {
      return;
    }
    setIsConfirmClicked(true);
    const orderDetails = {
      totalOrderPrice: totalOrderedPrice,
      items: orderedProducts().map((product) => product.formDetails),
      orderDate: moment().utcOffset("+05:30").format("YYYY-MM-DD"),
      deliveryDate: moment(deliveryDate)
        .utcOffset("+05:30")
        .format("YYYY-MM-DD"),
      userId: user._id,
    };

    if (isUserAdmin) {
      orderDetails.otherCustomer = {
        ...customerData,
      };

      const customerDeliveryDate = moment(customerData.deliveryDate)
        .utcOffset("+05:30")
        .format("YYYY-MM-DD");
      const customerOrderDate = moment(customerData.orderDate)
        .utcOffset("+05:30")
        .format("YYYY-MM-DD");

      orderDetails.orderDate = customerOrderDate;
      orderDetails.deliveryDate = customerDeliveryDate;

      if (customerData.phoneNumber && customerData.phoneNumber.length < 10) {
        alert("Please enter the correct phone number");
        return;
      }
    }

    checkForMinOrder(orderDetails, orderDetails.totalOrderPrice);
  };

  const checkForMinOrder = async (orderDetails) => {
    try {
      setIsConfirmClicked(true);
      let result = null;
      let pincodeId = null;
      if (user?._id) {
        const userDetails = await restAPIService.invoke(
          ApiEndpoint.GET_USER_DETAILS,
          { userId: user?._id }
        );
        const { zipid } = userDetails.data.data;
        pincodeId = zipid;
      } else {
        alert("Please select User");
        pincodeId = user.zipid;
      }
      result = await restAPIService.invoke(ApiEndpoint.GET_AREA_BY_ZIPId, {
        id: pincodeId,
      });
      const { minAmount } = result.data.data;
      setMinOrderedPrice(minAmount);

      if (totalOrderedPrice >= minAmount || isUserAdmin === 'true') {
        const selectedOrder = products.filter(
          (product) => product?.formDetails?.quantity
        );
        setSelectedOrder(selectedOrder);
        orderUpdate(orderDetails);
      } else {
        setMinOrderModalShow(true);
      }
      setIsConfirmClicked(false);
    } catch (error) {
      setIsConfirmClicked(false);
      console.log("@@@@ error @@@", error);
    }
  };

  const orderUpdate = async (orderDetails) => {
    try {
      let response = null;
      if (orderId) {
        response = await ProductService.updateOrder(orderDetails, orderId);
      } else {
        response = await ProductService.confirmOrder(orderDetails);
      }
      if (response && response.success) {
        const order = response.data;
        if (orderId) {
          removeSelectedOrder();
          removeMappedProduct();
          window.location.href = `/place-orders/orders/${orderId}/confirmed`;
        } else {
          window.location.href = `/place-orders/orders/${order._id}/confirmed`;
        }
      } else {
        alert("Something went wrong!. Contact admin for further queries");
      }
      setLoader(false);
      setIsConfirmOrderValid(true);
      setIsConfirmClicked(false);
    } catch (error) {
      setIsConfirmClicked(false);
      setLoader(false);
    }
  };

  return (
    <section
      className={
        totalOrderedPrice
          ? "products-wrapper products-bottom-wrapper"
          : "products-wrapper"
      }
    >
      <DeliveryComponent />
      <div className="products-container">
        <div className="products-list-heading products-edit">
          <div>
            <div className="heading">ORDER SUMMARY</div>
            <div className="heading-notice" style={{ maxWidth: "90%" }}>
              {isPriceWarning ? (
                <p>
                  Prices below are tentative. <br /> Final price for your order
                  will be updated at 12 PM.
                </p>
              ) : null}
            </div>
          </div>
          {/* ) : null
                    } */}
          <div>
            <AddMoreItemsButton onClick={addMoreItems}>
              Add More Items
            </AddMoreItemsButton>
          </div>
        </div>
        <div className="products-list">
          <div className="products-item products-heading-item">
            <div className="first-column">
              <div className="heading">Item</div>
            </div>
            <div className="second-column">
              <div className="heading">Quantity ordered</div>
            </div>
            <div className="third-column">
              <div className="heading"></div>
            </div>
          </div>
          <form>
            {console.log("products", products)}
            {products && products.length ? (
              products.map((product) => (
                <ProductItem
                  id={product._id}
                  product={product}
                  key={product._id}
                  productIndex={product.actualIndex}
                  isOrderSummary={true}
                  onHandleEditMode={handleEditMode}
                  onHandleRemoveNode={handleRemoveNode}
                />
              ))
            ) : (
              <p style={{ width: "100%", textAlign: "center" }}>
                No products added
              </p>
            )}
          </form>
        </div>
      </div>
      <div className="bottom-section">
        <div className="total-price-section">
          <div className="total-items-txt">
            {orderedProducts().length} items
          </div>
          <div className="total-approx-price">
            Approx {numberFormat(totalOrderedPrice)}
          </div>
        </div>
        <div className="btn-order-section">
          <button
            id="btn-confirmOrder"
            className="btn btn-inverted"
            onClick={handleConfirmOrder}
            disabled={
              (!isConfirmOrderValid && isConfirmClicked) || !products.length
            }
          >
            Confirm Order
          </button>
        </div>
      </div>

      <Modal
        open={editModel}
        onClose={() => setEditModel(false)}
        style={{ marginTop: "100px" }}
      >
        <h3 style={{ marginTop: "0" }}>Edit Item</h3>
        <hr />
        <div className="products-item products-heading-item product-list-heading">
          <div className="first-column">
            <div className="heading"></div>
          </div>
          <div className="second-column">
            <div className="heading">QUANTITY</div>
          </div>
          <div className="third-column">
            <div className="heading">UNIT</div>
          </div>
        </div>
        <form>
          {selectedProduct &&
            [selectedProduct].map((product) => (
              <ProductItem
                id={product._id}
                product={product}
                key={product._id}
                productIndex={product.actualIndex}
                isOrderSummary={false}
                fromEditOrder={true}
                handleChange={(product) => {
                  setSelectedProduct({ ...product });
                }}
                hideCross={true}
                hasError={setHasError}
              />
            ))}
        </form>
        <ConfirmButton
          disabled={hasError || !selectedProduct?.formDetails?.quantity}
          onClick={() =>
            handleChange(selectedProduct, selectedProduct.actualIndex)
          }
        >
          Confirm
        </ConfirmButton>
        <CancelButton
          onClick={() => {
            setEditModel(false);
            setSelectedProduct(null);
          }}
        >
          Cancel
        </CancelButton>
      </Modal>

      <Modal
        open={minOrderModalShow}
        onClose={() => setMinOrderModalShow(false)}
      >
        <p style={{ marginTop: "50px" }}>
          Your Order must be for minimum of <b>₹{minOrderedPrice}</b>
        </p>
        <Button onClick={() => setMinOrderModalShow(false)}>Go Back</Button>
      </Modal>
    </section>
  );
};

export default OrderEdit;

const ConfirmButton = styled.button`
  color: green;
  padding: 10px 20px;
  border: 2px solid green;
  border-radius: 4px;
  background: #fff;
  font-weight: bold;
`;

const AddMoreItemsButton = styled.button`
  color: green;
  padding: 10px 20px;
  border: 2px solid green;
  border-radius: 4px;
  background: #fff;
  white-space: nowrap;
  font-size: 15px;
`;

const CancelButton = styled.button`
  color: #888;
  padding: 10px 20px;
  border: none;
  background: #fff;
  font-weight: bold;
`;

const Button = styled.button`
  color: #14521d;
  padding: 10px 20px;
  border: 1px solid #14521d;
  border-radius: 4px;
  background: #fff;
  font-weight: bold;
`;
