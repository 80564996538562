import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FilterService from "../../services/_filter.service";
import { useLoader } from "../../shared/hooks";
import useDebounce from "../../shared/hooks/UseDebounce";
import { AiOutlineSearch } from "react-icons/ai";
import { useAuth } from "../../context/auth";

const Filters = ({ handleSearch, handleFilterTab }) => {
  const [filterList, setFilterList] = useState([]);
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const { user } = useAuth();
  const searchRef = React.useRef(null);
  const { setLoader } = useLoader((state) => state);

  useEffect(() => {
    if (user) {
      getAllCategory();
    }
  }, [user]);

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        handleSearch(debouncedSearchTerm);
      } else {
        handleSearch("");
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  const getAllCategory = async () => {
    try {
      let isFeaturedEnable = false;
      try {
        isFeaturedEnable = await FilterService.getFrequentEnabled(user._id);
        isFeaturedEnable = isFeaturedEnable.data.data;
      } catch (error) {
        isFeaturedEnable = false;
      }
      const filterList = await FilterService.getAll(isFeaturedEnable);
      setFilterList(filterList);
    } catch (error) {
      console.log(error);
    }
  };

  const onSelectFilter = (filterOption, index) => {
    setSearchTerm("");
    if (index !== selectedFilterIndex) {
      handleFilterTab(filterOption.id);
      setSelectedFilterIndex(index);
    } else {
      setSelectedFilterIndex(null);
      handleFilterTab(null);
    }
  };

  const onSearchClick = () => {
    // document.getElementById('searchBar').scrollIntoView(0,0)
    window.scroll({ top: document.getElementById('searchBar').offsetTop, behavior: 'smooth' })
  }


  const onClearText = () => {
    setSearchTerm("");
    searchRef.current.focus();
  }
  return (
    <FilterComponentContainer>
      <FiltersContainer>
        {filterList.length
          ? filterList.map((filter, index) => {
            return (
              <FilterButton
                key={`filter${index}`}
                type="button"
                color={selectedFilterIndex === index ? "#fff" : "#7e7e7e"}
                bgColor={selectedFilterIndex === index ? "#7e7e7e" : "#fff"}
                onClick={() => onSelectFilter(filter, index)}
              >
                {filter.name}
              </FilterButton>
            );
          })
          : null}
      </FiltersContainer>
      <SeachContainer>
        <SearchInput
          id='searchBar'
          type="text"
          onClick={onSearchClick}
          onChange={(e) => {
            if (selectedFilterIndex) {
              setSelectedFilterIndex(null);
            }
            setSearchTerm(e.target.value)
          }}
          name="search"
          value={searchTerm}
          autoComplete="off"
          ref={searchRef}
        />
        {!searchTerm && (
          <SearchIconContainer>
            <AiOutlineSearch size={20} />
          </SearchIconContainer>
        )}
        {searchTerm && (
          <TextSearchClear onClick={onClearText}>
            clear
          </TextSearchClear>
        )}
      </SeachContainer>
    </FilterComponentContainer>
  );
};

export default Filters;

const FilterComponentContainer = styled.section`
  margin: 0 15px;
`;

const FiltersContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: space-between !important;
  width: auto;
  overflow-x: scroll;
  padding-bottom: 15px;
`;

const FilterButton = styled.button`
  width: 30%;
  height: 40px;
  color: ${(props) => props.color};
  border: 1px solid #a5a5a5;
  outline: none;
  border-radius: 5px;
  margin-right: 10px;
  padding: 0px 15px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  background-color: ${(props) => props.bgColor};
  white-space: nowrap;
`;

const SeachContainer = styled.div`
  width: 100%;
  height: 35px;
  border-radius: 5px;
  display: flex;
  margin-top: 10px;
  border: 1px solid #a5a5a5;
  flex-direction: row;
  align-items: center;
`;

const SearchInput = styled.input`
  all: unset;
  font: 16px system-ui;
  color: #000;
  height: 100%;
  width: 100%;
  padding: 6px 10px;
`;

const SearchIconContainer = styled.span`
  color: #a5a5a5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 44px;
  height: 44px;
`;

const TextSearchClear = styled.p`
  padding-right: 9px;
  color: #777474cc;
`;
