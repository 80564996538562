// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/background-rect.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".header-container {\n  width: 100%;\n  display: flex;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  padding: 5px 12px 0 12px; \n  align-items: center;\n}\n\n.header-container a {\n  text-decoration: none;\n}\n.header-container .logo-text {\n  margin-left: 8px;\n  color: #fff;\n  font-size: 27px;\n  margin-top: -7px;\n  text-decoration: none;\n}\n\n.header-container .right {\n  display: flex;\n  align-items: center;\n}\n\n.header-container .btn-logout, .header-container a.btn-previous-order {\n  font-size: 15px;\n  color: #fff;\n  font-weight: bold;\n  margin-left: auto;\n  /* letter-spacing: 1px; */\n}\n\n.header-container .btn-logout {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  text-decoration: none;\n  cursor: pointer;\n}\n\n.header-container a.btn-previous-order {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  text-decoration: none;\n  border-bottom: none;\n  margin-right: 20px;\n}\n\n.btn-previous-order img, .btn-logout img {\n  margin-right: 10px;\n}\n\n.logo img {\n  margin-left: 5px;\n}", ""]);
// Exports
module.exports = exports;
