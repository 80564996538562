import React, { useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ProductService from "../../services/_product.service";
import ProductItem from "./ProductItem";
import { ApiEndpoint } from "../../services/api-endpoint";
import "./productItem.css";
import { numberFormat } from "../../helpers/utils";
import moment from "moment";
import { useAuth } from "../../context/auth";
import DeliveryDateService from "../../services/_deliveryDate.service";
import { RestApiService } from "../../services/rest-api.service";
import styled from "styled-components";
import { useLoader, useSelectedOrder } from "../../shared/hooks";
import Filters from "../Filters";
import logo from "../../assets/logo-white.png";
import { useHistory, useParams } from "react-router-dom";
const user = JSON.parse(localStorage.getItem("user")) || null;

function showPriceWarning() {
  const timeFormat = "HH:mm:ss";
  const currentTime = moment().utcOffset("+05:30");

  return currentTime.isBetween(
    moment("00:00:00", timeFormat).utcOffset("+05:30"),
    moment("12:00:00", timeFormat).utcOffset("05:30")
  );
}

function ProductCatalog(props) {
  const restAPIService = new RestApiService();
  const [products, setProducts] = React.useState([]);
  const [filteredProducts, setFilteredProducts] = React.useState([]);
  const [userFrequentProductIds, setUserFrequentProductIds] = React.useState(
    []
  );
  const history = useHistory();
  const params = useParams(); // const [orderedProducts, setOrderedProducts] = React.useState([]);
  const [state, setState] = React.useState({
    status: "loading",
    error: null,
    products: [],
    filteredProducts: [],
  });
  const { setLoader } = useLoader((state) => state);
  const [minOrderModalShow, setMinOrderModalShow] = React.useState(false);
  const [totalOrderedPrice, setTotalOrderedPrice] = React.useState(0);
  const [minOrderedPrice, setMinOrderedPrice] = React.useState(0);
  const { setSelectedOrder, selectedOrder } = useSelectedOrder(
    (state) => state
  );
  const [currentProductRef, setCurrentProductRef] = React.useState();

  const [isOrderSummary, setIsOrderSummary] = React.useState(false);

  const [isDeliveryDateSet, SetIsDeliveryDateSet] = React.useState(false);

  const [deliveryDate, setDeliveryDate] = React.useState();

  const [isOrderValid, setIsOrderValid] = React.useState(true);

  const [isConfirmClicked, setIsConfirmClicked] = React.useState(false);

  const [isConfirmOrderValid, setIsConfirmOrderValid] = React.useState(true);

  const [loading, setLoading] = React.useState(true);

  const { user } = useAuth();

  const handleDeliveryDate = async () => {
    const response = await DeliveryDateService.getDeliveryDate();
    if (response.success) {
      const deliveryDate = response.data.deliveryDate;
      setDeliveryDate(deliveryDate);
    }
  };

  if (!isDeliveryDateSet) {
    handleDeliveryDate();
    SetIsDeliveryDateSet(true);
  }

  const customerData = props.customerData;
  const isUserAdmin = props.isUserAdmin;

  const isPriceWarning = showPriceWarning();
  const orderId = params.orderId;
  useEffect(() => {
    console.log("hello selected order", selectedOrder);
    getAllProducts();
  }, []);

  useEffect(() => {
    if (currentProductRef) {
      currentProductRef.scrollIntoView();
      const id = currentProductRef.id;
      let inputId = id.replace("product-", "") + "-quantity";
      document.getElementById(inputId).focus();
      setCurrentProductRef(null);
    }
  }, [currentProductRef]);

  const getUserFrequentProducts = (products) => {
    ProductService.getUserFrequentProducts({ userId: user._id }).then(
      (res) => {
        const productObj = {};
        const productIds = res.data?.data?.products.map((elem, index) => {
          productObj[elem.productId] = {
            orderFrequency: elem.orderFrequency,
          };
          return elem.productId;
        });
        const productsWithFrequency = products.map((elem) => ({
          ...elem,
          ...productObj[elem._id],
        }));
        setProducts(productsWithFrequency);
        setUserFrequentProductIds(productIds);
      },
      (error) => {
        console.log("@@@@ frequent product fetch issue @@@", error);
      }
    );
  };

  const convertSelectedOrderListInObj = () => {
    const result = {};
    selectedOrder.forEach((elem, index) => {
      result[orderId && elem.productId ? elem.productId : elem._id] = elem;
    });
    return result;
  };

  const getAllProducts = () => {
    setLoading(true);
    ProductService.getAll().then(
      (res) => {
        if (res.data && res.data.success) {
          const selectedOrderObj = convertSelectedOrderListInObj();
          const products = res.data.data.allDocuments.map((elem, index) => {
            return selectedOrderObj[elem._id]
              ? {
                  ...elem,
                  formDetails: {
                    ...elem.formDetails,
                    quantity: selectedOrderObj[elem._id].formDetails
                      ? selectedOrderObj[elem._id].formDetails.quantity
                      : selectedOrderObj[elem._id].quantity,
                    uomId: selectedOrderObj[elem._id].formDetails
                      ? selectedOrderObj[elem._id].formDetails.uomId
                      : selectedOrderObj[elem._id].uomId,
                  },
                  actualIndex: index,
                }
              : {
                  ...elem,
                  actualIndex: index,
                };
          });
          console.log("hello product", selectedOrderObj, products);
          setFilteredProducts(products);
          setProducts(products);
          if (user) {
            getUserFrequentProducts(products);
          }
          setLoading(false);
        }
      },
      (error) => {
        setLoading(false);
        setState({ status: "error", error, products: [] });
      }
    );
  };

  const handleChange = (product, productIndex, quantityError) => {
    product.isError = quantityError;
    products[productIndex] = product;
    let totalPrice = 0;
    products.forEach((product) => {
      if (
        product.formDetails &&
        !product.formDetails.isError &&
        product.formDetails.price
      ) {
        totalPrice += product.formDetails.price;
      }
    });
    setTotalOrderedPrice(totalPrice);
    const errorProducts = products.filter((product) => product.isError);
    setIsOrderValid(!errorProducts.length);
    setCurrentProductRef("");
    setProducts(products);
  };

  function orderedProducts() {
    if (!products) return [];

    return products.filter((product) => {
      if (
        product.formDetails &&
        !product.formDetails.isError &&
        product.formDetails.price
      ) {
        return product.formDetails;
      }
    });
  }

  const handleSubmitOrder = async () => {
    try {
      if (!isOrderValid) {
        const errorElements = document.getElementsByClassName("quantity-error");
        if (errorElements.length) {
          const parentElements =
            document.getElementsByClassName("second-column");
          let selectedParentElement;
          for (let i = 0; i < parentElements.length; i++) {
            if (
              parentElements[i].getElementsByClassName("quantity-error").length
            ) {
              selectedParentElement = parentElements[i];
              break;
            }
          }
          selectedParentElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
          return;
        }
      }
      setLoader(true);
      setIsConfirmClicked(true);
      let result = null;
      let pincodeId = null;
      if (customerData?.id) {
        const userDetails = await restAPIService.invoke(
          ApiEndpoint.GET_USER_DETAILS,
          { userId: customerData?.id }
        );
        const { zipid } = userDetails.data.data;
        pincodeId = zipid;
      } else {
        alert('Please select User');
        pincodeId = user.zipid;
      }
      result = await restAPIService.invoke(ApiEndpoint.GET_AREA_BY_ZIPId, {
        id: pincodeId,
      });
      const { minAmount } = result.data.data;
      setMinOrderedPrice(minAmount);

      if (totalOrderedPrice >= minAmount || isUserAdmin) {
        const selectedOrder = products.filter(
          (product) => product?.formDetails?.quantity
        );
        setSelectedOrder(selectedOrder);
        if (orderId) {
          history.push(`/orders/preview/${orderId}`);
        } else {
          history.push(
            `/orders/preview?customerData=${JSON.stringify(
              customerData
            )}&isUserAdmin=${isUserAdmin}`
          );
        }
      } else {
        setMinOrderModalShow(true);
      }
      setIsConfirmClicked(false);
    } catch (error) {
      setIsConfirmClicked(false);
      console.log("@@@@ error @@@", error);
    } finally {
      setLoader(false);
    }
  };

  const handleEditMode = (ref) => {
    setIsOrderSummary(false);
    setCurrentProductRef(ref.current);
  };

  const handleConfirmOrder = async () => {
    setIsConfirmOrderValid(false);
    if (!isConfirmOrderValid) {
      return;
    }
    setIsConfirmClicked(true);
    const orderDetails = {
      totalOrderPrice: totalOrderedPrice,
      items: orderedProducts().map((product) => product.formDetails),
      orderDate: moment().utcOffset("+05:30").format("YYYY-MM-DD"),
      deliveryDate: moment(deliveryDate)
        .utcOffset("+05:30")
        .format("YYYY-MM-DD"),
      userId: user._id,
    };

    if (isUserAdmin) {
      orderDetails.otherCustomer = {
        ...customerData,
      };

      const customerDeliveryDate = moment(customerData.deliveryDate)
        .utcOffset("+05:30")
        .format("YYYY-MM-DD");
      const customerOrderDate = moment(customerData.orderDate)
        .utcOffset("+05:30")
        .format("YYYY-MM-DD");

      orderDetails.orderDate = customerOrderDate;
      orderDetails.deliveryDate = customerDeliveryDate;

      if (customerData.phoneNumber && customerData.phoneNumber.length < 10) {
        alert("Please enter the correct phone number");
        return;
      }
    }

    if (true) {
      setLoader(true);
      try {
        const response = await ProductService.confirmOrder(orderDetails);
        if (response.success) {
          const order = response.data;
          window.location.href = `/place-orders/orders/${order._id}/confirmed`;
        } else {
          alert("Something went wrong!. Contact admin for further queries");
        }
        setLoader(false);
        setIsConfirmOrderValid(true);
        setIsConfirmClicked(false);
      } catch (error) {
        setIsConfirmClicked(false);
        setLoader(false);
      }
    }
  };

  const handleFilterTab = (id) => {
    try {
      let filterProducts = [];
      if (id || id === 0) {
        if (id !== 0) {
          filterProducts = products.filter(
            (product) => product.category._id === id
          );
        } else {
          filterProducts = products.filter((product) =>
            userFrequentProductIds.includes(product._id)
          );
          filterProducts = filterProducts.sort(
            (a, b) => b.orderFrequency - a.orderFrequency
          );
        }
      } else {
        filterProducts = [...products];
      }
      setFilteredProducts(filterProducts);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSearch = (value) => {
    if (products && products.length) {
      if (value) {
        const filteredProducts = products.filter((product) =>
          product.name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredProducts(filteredProducts);
      } else {
        setFilteredProducts(products);
      }
    }
  };

  return (
    <>
      {!isOrderSummary ? (
        <Filters
          handleFilterTab={handleFilterTab}
          handleSearch={handleSearch}
        />
      ) : null}
      <section
        className={
          totalOrderedPrice
            ? "products-wrapper products-bottom-wrapper"
            : "products-wrapper"
        }
      >
        <div className="products-container">
          <div className="products-list-heading">
            {!isOrderSummary ? (
              <div className="products-list-caption">
                If you can’t find your item, please whatsapp us on 9871075578
              </div>
            ) : isPriceWarning ? (
              <div className="">
                <div className="heading">ORDER SUMMARY</div>
                <div className="heading-notice">
                  Prices below are tentative. <br />
                  Final price for your order will be updated at 12 PM.
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="products-list">
            <div className="products-item products-heading-item">
              <div className="first-column">
                <div className="heading">Item</div>
              </div>
              <div className="second-column">
                <div className="heading">
                  Quantity {isOrderSummary ? "ordered" : ""}
                </div>
              </div>
              <div className="third-column">
                <div className="heading">{isOrderSummary ? "" : "Unit"}</div>
              </div>
            </div>
            <form>
              {filteredProducts && filteredProducts.length ? (
                filteredProducts.map((product, index) => (
                  <ProductItem
                    id={product._id}
                    product={product}
                    key={product._id}
                    handleChange={handleChange}
                    productIndex={product.actualIndex}
                    isOrderSummary={isOrderSummary}
                    onHandleEditMode={handleEditMode}
                  />
                ))
              ) : (
                <div className="no-record">
                  <img src={logo} />
                  {loading ? (
                    <p>Loading items....</p>
                  ) : (
                    <p>No items to display</p>
                  )}
                </div>
              )}
            </form>
          </div>
        </div>
        {totalOrderedPrice ? (
          <div className="bottom-section">
            <div className="total-price-section">
              <div className="total-items-txt">
                {orderedProducts().length} items
              </div>
              <div className="total-approx-price">
                Approx {numberFormat(totalOrderedPrice)}
              </div>
            </div>
            {!isOrderSummary ? (
              <div className="btn-order-section">
                <button
                  id="btn-addSubmitOrder"
                  onClick={handleSubmitOrder}
                  className="btn btn-inverted"
                  disabled={isConfirmClicked}
                >
                  Submit Order
                </button>
              </div>
            ) : (
              <div className="btn-order-section">
                <button
                  id="btn-confirmOrder"
                  className="btn btn-inverted"
                  onClick={handleConfirmOrder}
                  disabled={!isConfirmOrderValid && isConfirmClicked}
                >
                  Confirm Order
                </button>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
        <Modal
          open={minOrderModalShow}
          onClose={() => setMinOrderModalShow(false)}
        >
          <p style={{ marginTop: "50px" }}>
            Your Order must be for minimum of <b>₹{minOrderedPrice}</b>
          </p>
          <Button onClick={() => setMinOrderModalShow(false)}>Go Back</Button>
        </Modal>
      </section>
    </>
  );
}

export default ProductCatalog;

const Button = styled.button`
  color: #14521d;
  padding: 10px 20px;
  border: 1px solid #14521d;
  border-radius: 4px;
  background: #fff;
  font-weight: bold;
`;
