import { API_URL } from "../app.config";
// export const API_URL = "https://admin-orders.netse.in/";

export const AUTH_ENDPOINT = "auth";
export const USER_ENDPOINT = {
  getUser: API_URL + "user",
  getUsers: API_URL + "customers",
};
export const OTP_ENDPOINT = {
  sendOTP: API_URL + "send-otp",
  verifyOtp: API_URL + "verify-otp",
};

export const PRODUCTS_ENDPOINT = {
  getAll: API_URL + "products",
  order: API_URL + "order",
  getPreviousOrders: API_URL + "previous-orders",
};

export const DELIVERY_ENDPOINT = {
  getDeliveryDate: API_URL + "delivery-date",
};

export const SETTINGS_ENDPOINT = {
  getSettings: API_URL + "settings",
};
