export class FiltersModel {

    name = '';
    id = '';
    isActive = false;
    status = '';
    priority = null;

    constructor(filter = null) {
        if (filter) {
            this.name = filter.name ? filter.name : this.name;
            this.id = filter._id ? filter._id : this.id;
            this.status = filter.status ? filter.status : this.status;
            this.priority = filter.priority ? filter.priority : this.priority;
        }
    }
}