import { FiltersModel } from "../model/filter.model";
import { ApiEndpoint } from "./api-endpoint";
import { RestApiService } from "./rest-api.service";

class FilterService {
  restAPIService = new RestApiService();

  getAll(isFeaturedEnable) {
    return new Promise((resolve, reject) => {
      (async () => {
        let response;
        try {
          response = await this.restAPIService.invoke(
            ApiEndpoint.GET_ALL_CATEGORIES
          );
          if (
            response.status === 200 &&
            response.data.data &&
            response.data.data.allDocuments.length
          ) {
            let list = response.data.data.allDocuments.map(
              (filter) => new FiltersModel(filter)
            );
            list = list.sort((a, b) => a.priority - b.priority);
            if (isFeaturedEnable) {
              list.unshift({ id: 0, name: "Frequent", priority: 0 });
            }
            resolve(list);
          } else {
            reject(response);
          }
        } catch (error) {
          reject(error.response.data);
        }
      })();
    });
  }

  getFrequentEnabled(id) {
    return new Promise((resolve, reject) => {
      (async () => {
        let response;
        try {
          response = await this.restAPIService.invoke(
            ApiEndpoint.GET_FREQUENT_ENABLED,
            {
              id,
            }
          );
          resolve(response.data.data ? true : false);
        } catch (error) {
          reject(error.response.data);
        }
      })();
    });
  }
}

export default new FilterService();
