import axios from 'axios';
import { OTP_ENDPOINT } from '../helpers/api';

class OtpService {
  sendOtp(data) {
    return new Promise(async (resolve, reject) => {
      let response;
      try {
        response = await axios.post(OTP_ENDPOINT.sendOTP, data);
        resolve(response.data);
      } catch(error) {
        resolve(error.response.data);
      }
    });
  }

  verifyOtp(data) {
    return new Promise(async (resolve, reject) => {
      let response; 
      try {
        response = await axios.post(OTP_ENDPOINT.verifyOtp, data);
        resolve(response.data);
      } catch(error) {
        resolve(error.response.data);
      }
    });
  }
}

export default new OtpService();