import React, { useState } from "react";
import ProductService from "../../services/_product.service";
import "./previousOrders.css";
import BackIconImage from "../../assets/icons/ic_arrow_drop_up.png";
import moment from "moment";
import { numberFormat } from "../../helpers/utils";

function OrderDetailsView(props) {
  const [order, setOrder] = useState();
  const [isOrderFetched, setIsOrderFetched] = useState("starting");
  const orderId = props.match.params.orderId;

  const getOrder = async () => {
    if (!orderId) return;
    setIsOrderFetched("pending");
    const response = await ProductService.getOrder(orderId);
    if (!response.success) return;
    const order = response.data;
    setOrder(order);
    setIsOrderFetched("done");
  };

  if (isOrderFetched === "starting") {
    getOrder();
  }

  return !order ? (
    ""
  ) : (
    <section className="order-details-wrapper">
      <div className="details-header-container">
        <div className="btn-back" onClick={() => { window.history.back() }}>
          <img src={BackIconImage} alt="Back" />
          <span>Back to All Orders</span>
        </div>
      </div>
      <div className="details-section-container">
        <div className="row">
          <div className="column">Order ID:</div>
          <div className="column">{order.orderNumber}</div>
        </div>
        <div className="row">
          <div className="column">Order Date:</div>
          <div className="column">
            {moment(order.orderDate).utcOffset("+05:30").format("MMM DD, YYYY")} | {" "}
            {moment(order.createdAt).utcOffset("+05:30").format("HH:MM")}
          </div>
        </div>
        <div className="row">
          <div className="column">Order Amount:</div>
          <div className="column">INR {(order.totalPrice).toFixed(2)}</div>
        </div>
      </div>
      <div className="order-summary-container">
        <div className="order-summary-title">Order Summary</div>

        <div className="order-products-list">
          <div className="row row-title">
            <div className="column">ITEM</div>
            <div className="column">QUANTITY ORDERED</div>
            <div className="column">Price</div>
          </div>
          {order &&
            order.items.map((product) => {
              const selectedUom = product.productDetails.associatedUOMs.find(uom => product.uomId.toString() === uom.uomId.toString());
              console.log(selectedUom);
              return (
              <div className="row row-item" key={product._id}>
                <div className="column">{product.productDetails.name}</div>
                <div className="column label-column">
                  <div className="unit-details">
                    <span className="label-value">{product.quantity}</span>
                    <span className="label-unit">{product.uomDetails.name}</span>
                  </div>
                </div>
                <div className="column">{numberFormat(product.productDetails.price * product.quantity * selectedUom.conversionNumber)}</div>
              </div>
            )})}
            {
              order && order.inputFields.map(inputField => (
                <div className="row row-item" key={inputField.name}>
                <div className="column">{inputField.name}</div>
                <div className="column label-column">
                  <div className="unit-details input-unit-details">
                    <span className="label-value"></span>
                    <span className="label-unit"></span>
                  </div>
                </div>
                <div className="column">{numberFormat(inputField.value)}</div>
              </div>
              ))
            }
        </div>
      </div>
    </section>
  );
}

export default OrderDetailsView;
