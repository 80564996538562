import axios from 'axios';
import { SETTINGS_ENDPOINT } from '../helpers/api';

class SettingsService {
  getSettings() {
    return new Promise(async (resolve, reject) => {
      let response;
      try {
        response = await axios.get(SETTINGS_ENDPOINT.getSettings);
        resolve(response.data);
      } catch(error) {
        resolve(error.response.data);
      }
    });
  }
}

export default new SettingsService();