import React from 'react';
import { Link , useHistory} from 'react-router-dom';
import {
  useSelectedOrder,
} from "../../shared/hooks";
import NetseLogo from '../../assets/logo.svg';
import orders from '../../assets/orders.png';
import signOut from '../../assets/logout.png';
import './header.css';
import { useAuth } from '../../context/auth';
import AuthService from '../../services/_authentication.service';

const Header = () => {
  let { removeSelectedOrder } = useSelectedOrder((state) => state);

  const { isAuthenticated } = useAuth();
  const history = useHistory();

  const gotoHome = () => {
    removeSelectedOrder();
    setTimeout(() => {
      history.push('/login');
    }, 100)
  }

  return (
    <section className="header-container">
      <div className="logo" onClick={gotoHome}><a href="javascript:void()"><img width="auto" height={60} src={NetseLogo} alt="" /></a></div>
      <div className="right">
        {
          isAuthenticated ?
            <Link to="/all-orders" className="btn-previous-order">
              <img width={30} height={30} src={orders} alt="" />
              Orders
            </Link> : ''
        }

        {
          isAuthenticated ?
            <div className="btn-logout" onClick={() => { AuthService.logout() }}>
              <img width={30} height={30} src={signOut} alt="" />
              <label>Sign Out</label>
            </div> : ''
        }
      </div>

    </section>
  )
}

export default Header;