import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import useSelectedPhone from "../../shared/hooks/useSelectedPhone";
import "./adminUserComponent.css";
import DeliveryDateService from "../../services/_deliveryDate.service";
import Select from "react-select";
import Axios from "axios";
import { USER_ENDPOINT } from "../../helpers/api";

function AdminUserComponent(props) {
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
  const [customerOrderDate, setCustomerOrderDate] = useState(
    Moment().utcOffset("+05:30").toDate()
  );
  const [customerDeliveryDate, setCustomerDeliveryDate] = useState("");
  const [isDeliveryDateSet, SetIsDeliveryDateSet] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const { selectedPhone, setSelectedPhone } = useSelectedPhone();

  const [customerOptions, setCustomerOptions] = useState([]);

  const onHandleCustomerData = () => {
    const data = {
      customerPhoneNumber: (selectedOption && selectedOption.value) || "",
      customerOrderDate: Moment(customerOrderDate)
        .utcOffset("+05:30")
        .format("YYYY-MM-DD"),
      customerDeliveryDate: Moment(customerDeliveryDate)
        .utcOffset("+05:30")
        .format("YYYY-MM-DD"),
      id: selectedOption?.id,
    };
    props.onHandleCustomerData(data);
  };

  const handleCustomerChange = (e) => {
    const inputValue = e.target.value;
    setCustomerPhoneNumber(inputValue.slice(0, 10));
  };

  const handleChange = (date) => {
    setCustomerOrderDate(date);
  };

  const handleDeliveryDate = async () => {
    const response = await DeliveryDateService.getDeliveryDate();
    if (response.success) {
      const deliveryDate = response.data.deliveryDate;
      setCustomerDeliveryDate(
        Moment(deliveryDate).utcOffset("+05:30").toDate()
      );
    }
  };

  const handleDeliveryDateChange = (date) => {
    setCustomerDeliveryDate(date);
    // onHandleCustomerData();
  };

  if (!isDeliveryDateSet) {
    handleDeliveryDate();
    SetIsDeliveryDateSet(true);
  }

  useEffect(() => {
    onHandleCustomerData();
  }, [customerOrderDate, customerDeliveryDate, selectedOption]);

  useEffect(() => {
    setSelectedOption(selectedPhone);
    if (!customerOptions.length && selectedPhone?.value) {
      handleUserChange(selectedPhone.value)
    }
  }, [selectedPhone]);

  const handleUserChange = (value) => {
    Axios.get(USER_ENDPOINT.getUsers + "?searchText=" + value)
      .then((response) => {
        if (response.data && response.data.success) {
          const customers = response.data.data.customers;
          const revsiedCustomersValues = customers.map((customer) => ({
            value: customer.phoneNumber,
            label: customer.phoneNumber,
            id: customer._id,
          }));
          setCustomerOptions(revsiedCustomersValues);
        } else {
          setCustomerOptions([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSelectedOption = (phone) => {
    setSelectedPhone(phone);
  };

  return (
    <div className="admin-user-wrapper">
      <div className="user-input-container">
        <div className="label">Customer Phone Number</div>
        <div className="input-wrapper customerPhoneNumber-wrapper">
          <Select
            defaultValue={selectedOption}
            value={selectedOption}
            onChange={onSelectedOption}
            onInputChange={handleUserChange}
            options={customerOptions}
          />
          {/* <SelectSearch name="customerPhoneNumber" search={true} onChange={handleUserChange} options={customerOptions} /> */}
          {/* <input type="number" maxLength="10" value={customerPhoneNumber} onChange={handleCustomerChange} required pattern= "[0-9]" /> */}
        </div>
      </div>
      <div className="user-input-container">
        <div className="label">Order Date</div>
        <div className="input-wrapper">
          <DatePicker
            dateFormat="yyyy-MM-dd"
            selected={customerOrderDate}
            onChange={handleChange}
            required
          />
        </div>
      </div>
      <div className="user-input-container">
        <div className="label">Delivery Date</div>
        <div className="input-wrapper">
          <DatePicker
            dateFormat="yyyy-MM-dd"
            selected={customerDeliveryDate}
            onChange={handleDeliveryDateChange}
            required
          />
        </div>
      </div>
    </div>
  );
}

export default AdminUserComponent;
