import create from "zustand";

const useSelectedOrder = create((set) => ({
  selectedOrder: [],
  setSelectedOrder: selectedOrder => set(() => {
    sessionStorage.setItem('selectedOrder', JSON.stringify(selectedOrder));
    return { selectedOrder }
  }),
  removeSelectedOrder: () => set(() => {
    sessionStorage.removeItem('selectedOrder');
    return { selectedOrder: [] }
  })
}));

export default useSelectedOrder;
