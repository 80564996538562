import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../context/auth';

function AuthenticatedRouteRedirection({ component: Component, ...rest }) {
  const { isAuthenticated } = useAuth();

  return (
    <Route 
      {...rest}
      render={(props) => (
        !isAuthenticated ? <Component {...props} /> : <Redirect to="/order" />
      )}
    />
  )
}

export default AuthenticatedRouteRedirection;