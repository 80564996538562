import React from "react";
import { useHistory } from "react-router-dom";
import cookie from 'react-cookies'
import "./login.css";
import SendOtpForm from "./SendOtpForm";
import VerifyOtpForm from "./VerifyOtpForm";
import OtpService from "../../services/_otp.service";
import ProductCatalog from "../ProductCatalog";
import { useLoader, useLoginUserDetails } from "../../shared/hooks";
import { RestApiService } from "../../services/rest-api.service";
import { ApiEndpoint } from "../../services/api-endpoint";
import { toast } from "react-toastify";
import { SERVER_ERROR } from "../../core/String";

const Login = (props) => {
  const restAPIService = new RestApiService();
  const history = useHistory();
  const [state, setState] = React.useState({
    isMobileNumberChangedOrSubmitted: false,
    mobileNumber: "",
    isOtpIncorrect: false,
    retries: 3,
  });

  const { setLoader } = useLoader((state) => state);

  const { setLoginUserDetails } = useLoginUserDetails((state) => state);

  const handleSendOtpFormSubmit = async (mobileNumber) => {
    setLoader(true);
    try {
      const response = await OtpService.sendOtp({
        phoneNumber: mobileNumber,
      });
      if (response.success) {
        setState({
          ...state,
          isMobileNumberChangedOrSubmitted: true,
          mobileNumber,
        });
      } else if (response.userNotFound) {
        window.location.href = "/not-found-user";
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const handleResendOtp = async (phoneNumber) => {
    setLoader(true);
    try {
      const response = await OtpService.sendOtp({
        phoneNumber,
      });
      if (response.success) {
        setState({
          ...state,
          isOtpIncorrect: false,
        });
        alert("OTP Sent Successfully");
      } else {
        setState({
          ...state,
          isOtpIncorrect: true,
        });
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const handleVerifyOtp = async (phoneNumber, otp) => {
    setLoader(true);
    try {
      const response = await OtpService.verifyOtp({
        phoneNumber,
        otp,
      });
      if (response.success) {
        if (response.redirect) {
          setState({
            ...state,
            isOtpIncorrect: false,
          });
          setLoginUserDetails({
            phoneNumber
          });
          history.push("/address");
        } else {
          setState({
            ...state,
            isOtpIncorrect: false,
          });
          const user = response.user;
          const isUserAdmin = !!response.isUserAdmin;
          if (user !== undefined) {
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("isUserAdmin", isUserAdmin);
            cookie.save('user', JSON.stringify(user), { path: '/' })
            cookie.save('isUserAdmin', isUserAdmin, { path: '/' })
            window.location.reload();
          }
        }
      } else if (response.userNotFound) {
        registerUserAsInterested(phoneNumber);
      } else {
        const retries = (response && response.retries) || 0;
        setState({
          ...state,
          isOtpIncorrect: true,
          retries,
        });
        if (retries === 0) {
          this.handleMobileReset();
        }
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const registerUserAsInterested = async (phoneNumber) => {
    setLoader(true);
    try {
      const result = await restAPIService.invoke(
        ApiEndpoint.CREATE_INTERESTED_USER,
        null,
        { phoneNumber }
      );
      setLoginUserDetails(result.data.body);
      setLoader(false);
      history.push("/address");
    } catch (error) {
      setLoader(false);
      console.log("hdllo", error);
      toast.error(SERVER_ERROR.message);
    }
  };

  const handleMobileReset = () => {
    setState({
      ...state,
      isMobileNumberChangedOrSubmitted: false,
      mobileNumber: "",
      isOtpIncorrect: false,
    });
  };

  return (
    <section>
      {state.isMobileNumberChangedOrSubmitted ? (
        <VerifyOtpForm
          mobileNumber={state.mobileNumber}
          onHandleChangeMobileNumber={handleMobileReset}
          onResendOtp={handleResendOtp}
          isOtpIncorrect={state.isOtpIncorrect}
          retries={state.retries}
          onHandleVerifyOtp={handleVerifyOtp}
        />
      ) : (
        <SendOtpForm onHandleSendOtpFormSubmit={handleSendOtpFormSubmit} />
      )}

      <div className="non-editable-wrapper">
        <div className="non-editable-container"></div>
        <ProductCatalog />
      </div>
    </section>
  );
};

export default Login;
