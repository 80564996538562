import React, { useState } from 'react';
import { Link } from "react-router-dom";
import './notFoundUser.css';
import FruitsImage from '../../assets/fruits.png';
import SettingsService from '../../services/_settings.service';
import WhatsappIcon from '../../assets/icons/whatsapp.png'


function NotFoundUser() {

    const [rateListPdf, setRateListPdf] = useState('');

    const getSettings = async () => {
      const response = await SettingsService.getSettings();
      if(response.success) {
        const { settings } = response.data;
        console.log(settings.rateListPdf);
        setRateListPdf(settings.rateListPdf);
      }
    }

    getSettings();

    const handleDownloadFile = () => {
      window.open(rateListPdf, 'Download');  
    }

    return (
      <div className="notFound-wrapper">
        <div className="image-container">
          <img src={FruitsImage} alt="Netse" />
        </div>
        <div className="notFound-content-container">
          <div className="heading">Welcome to Netse!</div>
          <div className="content">
          We will setup your account within the next 24 hours so you can place your order here.
          <br /><br />
          <strong>Meanwhile, please whatsapp your order on 9871075578</strong>
          </div>

          <div className="btn-container">
            <br />
            <br />
            {/* <a className="btn btn-inverted btn-inverted-bordered btn-rateList" href={rateListPdf} target="_blank" rel="noopener noreferer" download={rateListPdf}>Download Rate List</a> */}
            <Link className="btn btn-inverted btn-inverted-bordered btn-rateList" onClick={handleDownloadFile}>Download Rate List</Link>
            <br />
            <br />
            <a href="whatsapp://send?phone=+919871075578" className="btn btn-whatsapp"><img src={WhatsappIcon} alt="whatsapp"/> Contact us on Whatsapp</a>
          </div>
        </div>
      </div>
    )
}

export default NotFoundUser;