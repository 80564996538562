import moment from 'moment';

export const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  }).format(value);


export function calculateDeliveryTimeDetails() {
  const currentDateTime = moment().utcOffset("+05:30");
  const scheduledTimeFormat = moment().set({
    hours: 22,
    minute: 0,
    second: 0,
  });

  const isDeliveryTomorrow = currentDateTime.isBefore(scheduledTimeFormat);
  const dayToCalculate = isDeliveryTomorrow ? 1 : 2;
  const deliveryDate = moment().add(dayToCalculate);

  return {
    deliveryDate,
    isDeliveryTomorrow,
  };
}